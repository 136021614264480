<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">Master Pejabat</h4>
            </div>
            <span @click="addBtnHandle" class="btn btn-primary" v-if="validasi(['is_admin', 'pejabat-add'])">
              Tambah Baru
            </span>
          </div>
          <div class="card-body">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :line-numbers="true"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [5, 10, 20],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'Selanjutnya',
                prevLabel: 'Sebelumnya',
                rowsPerPageLabel: 'Jumlah baris',
                ofLabel: 'dari',
                pageLabel: 'halaman', // for 'pages' mode
                allLabel: 'Semua',
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <b-button size="sm" @click="editBtnHandle(props)" variant="primary" class="mr-2" :show="validasi(['is_admin', 'pejabat-edit'])" title="Edit">
                    <i class="dripicons dripicons-pencil"></i>
                  </b-button >
                  <b-button size="sm" @click="showActive(props)" variant="success" :show="validasi(['is_admin', 'pejabat-edit'])" class="mr-2" title="Ubah Status">
                    <i class="dripicons dripicons-checkmark"></i>
                  </b-button>
                  <b-button size="sm" @click="showDelete(props)" variant="danger" :show="validasi(['is_admin', 'pejabat-delete'])" class="mr-2" title="Hapus">
                    <i class="dripicons dripicons-trash"></i>
                  </b-button>
                </span>
                <span v-else-if="props.column.field == 'aktif_icon'">
                  <b-badge :variant="customRow(props)" >{{ props.row.status_aktif }}</b-badge>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
          <!-- MODAL DELETE -->
          <b-modal :no-close-on-backdrop="true" id="modalDelete" title="Hapus Pejabat">
            <p>Apakah anda yakin untuk menghapus pejabat <strong>{{ lblPopup }}</strong> ?</p>
            <template #modal-footer="{ cancel }">
              <b-button variant="primary" @click="cancel()">
                Batal
              </b-button>
              <b-button variant="danger" @click="modalDelete()">
                Hapus
              </b-button>
            </template>
          </b-modal>
          <!-- MODAL ACTIVE -->
          <b-modal :no-close-on-backdrop="true" id="modalActive" title="Ubah Status">
            <p>Apakah anda yakin untuk mengubah status <strong>{{ lblPopup }}</strong> ?</p>
            <template #modal-footer="{ cancel }">
              <b-button variant="danger" @click="cancel()">
                Batal
              </b-button>
              <b-button variant="primary" @click="modalActive()">
                Ya
              </b-button>
            </template>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-good-table/dist/vue-good-table.css'
  import { VueGoodTable } from 'vue-good-table';
  import { getDataService, deleteDataService, putDataService } from '../../../store/modules/crudservices'
  import { cekAkses } from '../../../config/authenticate'

  export default {
    name:'PejabatList',
    data () {
      return {
        modalTitle: '',
        popupIsActive: null,
        lblPopup: '',
        columns:[{
            label: 'NIP',
            field: 'nip',
            filterOptions: {
              styleClass: 'class2', 
              enabled: true,
              trigger: '',
            }
          }, {
            label: 'Nama Pegawai',
            field: 'full_name',
            filterOptions: {
              styleClass: 'class2', 
              enabled: true,
              trigger: '',
            }
          }, {
            label: 'Autorisasi',
            field: 'autorisasi',
            filterOptions: {
              styleClass: 'class2', 
              enabled: true,
              trigger: '',
            }
          }, {
            label: 'Aktif',
            field: 'aktif_icon'
          }, {
            label: 'Aksi',
            field: 'action',
            sortable: false,
            width: '150px',
            tdClass: 'text-center',
            hidden: !this.validasi(['is_admin', 'pejabat_edit', 'pejabat_delete'])
          }
        ],
        rows: [],
        form:[]
      }
    },
    methods: {
      customRow(val){
        return val.row.is_active ? "success" : "warning"
      },
      addBtnHandle() {
        this.$router.push('/master/pejabat/add')
      },
      editBtnHandle(props) {
        const url = `/master/pejabat/edit/${props.row.id}`
        this.$router.push(url)
      },
      refreshLists(){
        getDataService('/pejabat-grid').then(data => {
          this.rows = data.data
        })
      },
      showActive(val){
        this.lblPopup = val.row.name
        this.form.id = val.row.id
        this.form.active = val.row.is_active
        this.$bvModal.show('modalActive')
      },
      modalActive(){
        const url = `/pejabat/${this.form.id}/active`
        
        const _data = { 
          '_method': 'PUT',
          'is_active': this.form.active == true ? false : true
        }

        putDataService(url, _data).then( response => {
          if(response.data.success)
            this.$bvModal.hide('modalActive')
            this.refreshLists()
        })
      },
      showDelete(val){
        this.lblPopup = val.row.name
        this.form.id = val.row.id
        this.$bvModal.show('modalDelete')
      },
      modalDelete(){
        const url = `/pejabat/${this.form.id}`
        deleteDataService(url).then( response => {
          if(response.data.success)
            this.$bvModal.hide('modalDelete')
            this.refreshLists()
        })
      },
      validasi(perms) {
        return cekAkses(perms)
      },
    },
    components: {
      VueGoodTable,
    },
    mounted() {
      this.refreshLists()
    }
  }
</script>